/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-03-19",
    versionChannel: "nightly",
    buildDate: "2023-03-19T00:19:16.337Z",
    commitHash: "5aac938e868e7dab2550d9482f754a6daf314921",
};
